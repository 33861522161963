<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
          <!-- <a @click="toBack()">Dashboard</a> <v-icon>mdi-chevron-right</v-icon> -->
           รายงาน
      </v-card-title>
    </v-card>

    <!-- search -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
            <v-icon color="blue accent-2" >fa-sliders-h</v-icon>
            <span class="ml-2">ค้นหา</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row row>
                <v-col cols="12" md="2" lg="1" class="pb-3">
                    <span>วันที่ : </span>
                </v-col>
                <v-col cols="12" md="3" class="pb-3">
                    <v-menu
                        ref="search_menu_date_from"
                        v-model="search_menu_date_from"
                        :close-on-content-click="false"
                        :return-value.sync="search_date_from"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_search_date_from"
                            @change="value => computed_search_date_from = value"
                            autocomplete="off"
                            label="เริ่ม"
                            outlined
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="3"  class="pb-3">
                    <v-menu
                        ref="search_menu_date_to"
                        v-model="search_menu_date_to"
                        :close-on-content-click="false"
                        :return-value.sync="search_date_to"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_search_date_to"
                            @change="value => computed_search_date_to = value"
                            autocomplete="off"
                            label="สิ้นสุด"
                            outlined
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row row>
                <v-col cols="12" md="2" lg="1" class="pb-3">
                    <h5>ร้านค้า : </h5>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="pb-3">
                    <v-select
                        v-model="shoplist"
                        :items="select_shop"
                        @change="changeshoplist()"
                        item-text="shop.name"
                        item-value="shop.id"
                        outlined
                        hide-details
                        dense
                        multiple
                    />
                </v-col>
            </v-row>
            <v-row row>
                <v-col cols="12" md="2" lg="1" class="pb-3">
                    <h5>ประเภทรายได้ : </h5>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="pb-3">
                    <v-select
                        v-model="incomelist"
                        :items="select_income"
                        @change="changeincomelist()"
                        item-text="text"
                        item-value="value"
                        outlined
                        hide-details
                        multiple
                        dense
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-btn :loading="loading_btn" dark color="primary" @click="loaddata()" class="mr-3">
                      <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                      ค้นหา
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <v-row class="ma-0">
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" dark color="#d14035" max-height="150">
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <h3 class="pb-4" >จำนวนร้านค้า</h3>
                                <h2 v-if="Data_general.allShop!=null && Data_general.activeShop != null">
                                    {{format_number(Data_general.activeShop) + " / " + format_number(Data_general.allShop)}}
                                </h2>
                                <h2 v-else>{{0 + " / " + 0}}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon dark x-large >fa-store</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" dark color="#eb8a3c" max-height="150">
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <h3 class="pb-4">จำนวนออเดอร์ทั้งหมด</h3>
                                <h2 class="pb-4" v-if="Data_general.orderCount!=null && Data_general.totalOrderCount != null">
                                    {{format_number(Data_general.orderCount) + " / "+format_number(Data_general.totalOrderCount) }}
                                </h2>
                                <h2 class="pb-4" v-else>{{0 + " / " + 0}}</h2>
                                <h4> {{"เฉลี่ย " +  format_number(Data_general.averageOrderPerDay) + " ออเดอร์ /วัน" }}  </h4>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon x-large >fa-box-open</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" dark color="#775845" max-height="150">
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <h3 class="pb-4" >รายได้รวม</h3>
                                <h2 class="pb-4" v-if="Data_general.totalIncome!=null">
                                    {{format_price(Data_general.totalIncome)}}
                                </h2>
                                <h2 v-else>{{0 }}</h2>
                                <h4> {{"รายได้เฉลี่ย " + format_price(Data_general.averageIncomePerDay)  + " บาท/วัน" }}  </h4>
                                <h4> {{"รายได้เฉลี่ย " + format_price(Data_general.averageIncomPerBox)+ " บาท/กล่อง " }}  </h4>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon dark x-large >fa-money-bill</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">จำนวนออเดอร์ทั้งหมด</span>
            <v-select
                v-model="Transactionorder"
                :items="selectDate"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                style="max-width: 160px!important; font-size: 16.5px !important;"
            ></v-select>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-if="Transactionorder == 1">
                    <GChart
                        type="LineChart"
                        :data="Data_chart.graph_order_count"
                        :options="chartOptionsorder"
                    />
                </v-col>
                <v-col cols="12" v-else-if="Transactionorder == 2">
                    <GChart
                        type="ColumnChart"
                        :data="Data_chart.graph_order_count_by_Month"
                        :options="chartOptionsorder"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#02475E">
                ออเดอร์
            </div>
        </v-card-text>
    </v-card>
    <!-- graphincome -->
    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">รายได้ทั้งหมด</span>
            <v-select
                v-model="Transactionincome"
                :items="selectDate"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                style="max-width: 160px!important; font-size: 16.5px !important;"
            ></v-select>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-if="Transactionincome == 1">
                    <GChart
                        type="LineChart"
                        :data="Data_chart.graph_income"
                        :options="chartOptionsincome"
                    />
                </v-col>
                <v-col cols="12" v-else-if="Transactionincome == 2">
                    <GChart
                        type="ColumnChart"
                        :data="Data_chart.graph_income_by_Month"
                        :options="chartOptionsincome"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#1b4a58">
                รายได้รวม
            </div>
        </v-card-text>
    </v-card>
    <!-- graph_TransactionFee -->
    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">ค่าธรรมเนียมการใช้ระบบ</span>
            <v-select
                v-model="Transaction"
                :items="selectDate"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                style="max-width: 160px!important; font-size: 16.5px !important;"
            ></v-select>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-if="Transaction == 1">
                    <GChart
                        type="LineChart"
                        :data="Data_chart.graph_TransactionFee"
                        :options="chartOptionstransactionfee"
                    />
                </v-col>
                <v-col cols="12" v-else-if="Transaction == 2">
                    <GChart
                        type="ColumnChart"
                        :data="Data_chart.graph_TransactionFee_Month"
                        :options="chartOptionstransactionfee"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#0e1f37">
                รายได้ค่าธรรมเนียมการใช้ระบบ
            </div>
        </v-card-text>
    </v-card>
    <!-- graph_order_count_compare -->
    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" v-if="Data_chart.graph_order_count_compare.length > 0 && Data_chart.graph_order_count_compare != null">
        <v-card-title>
            <span class="mr-3">เปรียบเทียบจำนวนออเดอร์แต่ละร้าน</span>
            <v-select
                v-model="ordercompare"
                :items="selectDate"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                style="max-width: 160px!important; font-size: 16.5px !important;"
            ></v-select>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-if="ordercompare == 1">
                    <GChart
                        type="ComboChart"
                        :data="Data_chart.graph_order_count_compare"
                        :options="chartOptionsordercompare"
                    />
                </v-col>
                <v-col cols="12" v-else-if="ordercompare == 2">
                    <GChart
                        type="ComboChart"
                        :data="Data_chart.graph_order_count_by_Month_compare"
                        :options="chartOptionsordercomparemonth"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#90352B">
                เปรียบเทียบออเดอร์และร้านค้า
            </div>
        </v-card-text>
    </v-card>
    <!-- graph_income_compare -->
    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" v-if="Data_chart.graph_order_count_compare.length > 0 && Data_chart.graph_order_count_compare.length != null">
        <v-card-title>
            <span class="mr-3">เปรียบเทียบรายได้ของแต่ละร้าน</span>
            <v-select
                v-model="Incomecompare"
                :items="selectDate"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                style="max-width: 160px!important; font-size: 16.5px !important;"
            ></v-select>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-if="Incomecompare == 1">
                    <GChart
                        type="ComboChart"
                        :data="Data_chart.graph_income_compare"
                        :options="chartOptionsincomecompare"
                    />
                </v-col>
                <v-col cols="12" v-else-if="Incomecompare == 2">
                    <GChart
                        type="ComboChart"
                        :data="Data_chart.graph_income_by_Month_compare"
                        :options="chartOptionsincomecomparemonth"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#90352B">
                เปรียบเทียบรายได้
            </div>
        </v-card-text>
    </v-card>
    <!-- bar_order_count_compare -->
    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">เปรียบเทียบจำนวนออเดอร์ทั้งหมด</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <GChart
                        type="ColumnChart"
                        :data="Data_chart.bar_order_count_compare"
                        :options="barOptionsOrder"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#02475E">
                เปรียบเทียบจำนวนออเดอร์
            </div>
        </v-card-text>
    </v-card>
    <!-- bar_income_compare -->
    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">เปรียบเทียบรายได้ทั้งหมด</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <GChart
                        type="ColumnChart"
                        :data="Data_chart.bar_income_compare"
                        :options="barOptionsIncome"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#02475E">
                เปรียบเทียบรายได้ร้านค้า
            </div>
        </v-card-text>
    </v-card>
    <!-- pie -->
    <v-row class="ma-0">
        <v-col cols="12" md="4" class="pa-0">
            <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
                <v-card-title>
                    <span class="mr-3">สัดส่วนจำนวนออเดอร์แต่ละร้านค้า</span>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <GChart
                                type="PieChart"
                                :data="Data_chart.pie_order_count_compare"
                                :options="pieOptionOrder"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-0">
            <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
                <v-card-title>
                    <span class="mr-3">สัดส่วนรายได้แต่ละร้านค้า</span>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <GChart
                                type="PieChart"
                                :data="Data_chart.pie_income_compare"
                                :options="pieOptionOrder"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-0">
            <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
                <v-card-title>
                    <span class="mr-3">สัดส่วนกำไรแต่ละร้านค้า</span>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <GChart
                                type="PieChart"
                                :data="Data_chart.pie_profit_compare"
                                :options="pieOptionOrder"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <!-- datatable -->
    <v-card elevation="1" class="text-center" >
        <v-card-title>
            รายละเอียดทั้งหมด
            <v-spacer/>


              <vue-excel-xlsx
                  v-if="tab == 0 && Data_chart.datatable_group_shop.length > 0"
                  :data="Data_chart.datatable_group_shop"
                  :columns="excel_headers_group_shop"
                  file-name="รายละเอียดแต่ละร้านค้า"
                  sheet-name="shee1"
                  >
                  <v-btn  size="1" color="success" dark class="mr-1"><v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                    Export Excel
                  </v-btn>
              </vue-excel-xlsx>


              <vue-excel-xlsx
                  v-if="tab == 1 && Data_chart.datatable.length > 0"
                  :data="Data_chart.datatable"
                  :columns="excel_headers_group_day"
                  file-name="รายละเอียดแยกตามวัน"
                  sheet-name="shee1"
                  >
                  <v-btn  size="1" color="success" dark class="mr-1"><v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                    Export Excel
                  </v-btn>
              </vue-excel-xlsx>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                left
                >
                <v-tab> รายละเอียดแต่ละร้านค้า </v-tab>
                <v-tab> รายละเอียดแยกตามวัน </v-tab>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <v-data-table
                                :headers="headers_group_shop"
                                :items="Data_chart.datatable_group_shop"
                                :items-per-page="100"
                                class="elevation-0 packhai-border-table  hidden-xs-only"
                                style="min-height: 300px"
                                hide-default-footer
                            >

                                <!-- <template v-slot:item.shopName="{ item }">
                                    {{ item.shopName }}
                                </template>
                                <template v-slot:item.transactionFee="{ item }">
                                    {{ (item.transactionFee == "" || item.transactionFee == null?"0.00": item.transactionFee) }}
                                </template>
                                <template v-slot:item.orderCount="{ item }">
                                    {{ (item.orderCount== "" || item.orderCount == null?"0":item.orderCount) }}
                                </template>
                                <template v-slot:item.averageOrderCount="{ item }">
                                    {{ (item.averageOrderCount== "" || item.averageOrderCount== null?"0":item.averageOrderCount) }}
                                </template>
                                <template v-slot:item.totalIncome="{ item }">
                                    {{ (item.totalIncome == "" || item.totalIncome== null?"0.00":item.totalIncome) }}
                                </template>
                                <template v-slot:item.packPrice="{ item }">
                                    {{ (item.packPrice == "" || item.packPrice== null?"0.00":item.packPrice) }}
                                </template>
                                <template v-slot:item.discount="{ item }">
                                    {{ (item.discount == "" || item.discount== null?"0.00":item.discount) }}
                                </template>
                                <template v-slot:item.connectFee="{ item }">
                                    {{ (item.connectFee == "" || item.connectFee== null?"0.00":item.connectFee) }}
                                </template>
                                <template v-slot:item.bubblePrice="{ item }">
                                    {{ (item.bubblePrice == "" || item.bubblePrice== null?"0.00":item.bubblePrice) }}
                                </template>
                                <template v-slot:item.codPrice="{ item }">
                                    {{ (item.codPrice == "" || item.codPrice== null?"0.00":item.codPrice) }}
                                </template>
                                <template v-slot:item.boxPrice="{ item }">
                                    {{ (item.boxPrice == "" || item.boxPrice== null?"0.00":item.boxPrice) }}
                                </template>
                                <template v-slot:item.deliveryPrice="{ item }">
                                    {{ (item.deliveryPrice == "" || item.deliveryPrice== null?"0.00":item.deliveryPrice) }}
                                </template>           -->
                                <!-- <template v-slot:item.averageProfitPerOrder="{ item }">
                                    {{ format_price(item.averageProfitPerOrder) }}
                                </template> -->
                             </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text v-if="Data_chart.datatable.length != 0">
                            <v-data-table
                                :headers="headers"
                                :items="Data_chart.datatable"
                                :items-per-page="100"
                                class="elevation-0 packhai-border-table  hidden-xs-only"
                                style="min-height: 300px"
                                hide-default-footer
                            >

                                <!-- <template v-slot:item.shopName="{ item }">
                                    {{ item.shopName }}
                                </template>
                                <template v-slot:item.transactionFee="{ item }">
                                    {{ (item.transactionFee == "" || item.transactionFee == null?"0.00": item.transactionFee) }}
                                </template>
                                <template v-slot:item.orderCount="{ item }">
                                    {{ (item.orderCount== "" || item.orderCount == null?"0":item.orderCount) }}
                                </template>
                                <template v-slot:item.averageOrderCount="{ item }">
                                    {{ (item.averageOrderCount== "" || item.averageOrderCount== null?"0":item.averageOrderCount) }}
                                </template>
                                <template v-slot:item.totalIncome="{ item }">
                                    {{ (item.totalIncome == "" || item.totalIncome== null?"0.00":item.totalIncome) }}
                                </template>
                                <template v-slot:item.packPrice="{ item }">
                                    {{ (item.packPrice == "" || item.packPrice== null?"0.00":item.packPrice) }}
                                </template>
                                <template v-slot:item.discount="{ item }">
                                    {{ (item.discount == "" || item.discount== null?"0.00":item.discount) }}
                                </template>
                                <template v-slot:item.connectFee="{ item }">
                                    {{ (item.connectFee == "" || item.connectFee== null?"0.00":item.connectFee) }}
                                </template>
                                <template v-slot:item.bubblePrice="{ item }">
                                    {{ (item.bubblePrice == "" || item.bubblePrice== null?"0.00":item.bubblePrice) }}
                                </template>
                                <template v-slot:item.codPrice="{ item }">
                                    {{ (item.codPrice == "" || item.codPrice== null?"0.00":item.codPrice) }}
                                </template>
                                <template v-slot:item.boxPrice="{ item }">
                                    {{ (item.boxPrice) }}
                                </template>
                                <template v-slot:item.deliveryPrice="{ item }">
                                    {{ (item.deliveryPrice) }}
                                </template>           -->
                             </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios' 
  import Loading from '@/website/components/Loading'
  import { GChart } from 'vue-google-charts'
  import { reportService_dotnet,shopService_dotnet } from '@/website/global'
  import {format_number, format_price} from '@/website/global_function'
  export default {
    components: {
      Loading,
      GChart
    },
    data:() =>({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      loading_btn:false,

      search_menu_date_from: false,
      search_menu_date_to: false,
      search_date_from: null,
      search_date_to: null,


      branchID:null,
      shoplist:[0],
      shoplist_temp:[],
      select_shop:null,
      incomelist:[0],
      incomelist_temp:[],
      select_income:[{"text":"--ทุกรายได้--","value":0},{"text":"ค่าขนส่ง","value":1},{"text":"ค่าบริการ COD","value":2},{"text":"ค่ากล่อง","value":3},
                     {"text":"ค่าแพ็ค","value":4},{"text":"ค่าบับเบิ้ล","value":5},{"text":"ค่าเชื่อมต่อ VRICH","value":6}],
      tab:null,
      selectDate:[{"text":"ตามวันที่","value":1},{"text":"ตามเดือน","value":2}],
      Transactionincome:1,
      Transactionorder:1,
      Transaction:1,
      ordercompare:1,
      Incomecompare:1,

      Data_general:null,
      chartOptionsorder:{
          colors:['#2d248a','#614ad3'],
          bar: {groupWidth: '10%'}
      },
      chartOptionsincome:{
          colors:['#a39391','#e79686'],
          bar: {groupWidth: '10%'}
      },
      chartOptionstransactionfee:{
          colors:['#f45249','#716e77'],
          bar: {groupWidth: '10%'}
      },
      chartOptionsordercompare:{
          colors:['#d14035','#eb8a3c','#ebb481','#775845'],
          seriesType: 'line',
      },
      chartOptionsordercomparemonth:{
          colors:['#542E71','#FB3640','#FDCA40','#A799B7'],
          seriesType: 'bars',
          hAxis: {title: 'Month'},
          vAxis: {title: 'Shop'},
      },
      chartOptionsincomecompare:{
          colors:['#d14035','#eb8a3c','#ebb481','#775845'],
          seriesType: 'line',
      },
      chartOptionsincomecomparemonth:{
          colors:['#542E71','#FB3640','#FDCA40','#A799B7'],
          seriesType: 'bars',
          hAxis: {title: 'Month'},
          vAxis: {title: 'Shop'},
      },
      barOptionsOrder:{
          colors:['#1b4a58','#0e1f37'],
      },
      barOptionsIncome:{
          colors:['#f45249','#1b4a58'],
      },
      pieOptionOrder:{
          chartArea:{left:0,top:0,width:'100%',height:'100%'},
          colors:['#6F69AC','#95DAC1','#FFEBA1','#FD6F96']
      },
      pieOptionIncome:{
          chartArea:{left:0,top:0,width:'100%',height:'100%'},
      },
      Data_chart:null,
      headers:[
              { text: 'วันที่', align: 'center', sortable: false, value: 'date'},
              { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName'},
              { text: 'จำนวนออเดอร์', align: 'right', sortable: false, value: 'orderCount'},
              { text: 'ออเดอร์เฉลี่ย / วัน', align: 'right', sortable: false, value: 'averageOrderCount'},
              { text: 'ค่าขนส่ง', align: 'right', sortable: false, value: 'deliveryPrice'},
              { text: 'ค่าบริการ COD', align: 'right', sortable: false, value: 'codPrice'},
              { text: 'ค่ากล่อง', align: 'right', sortable: false, value: 'boxPrice'},
              { text: 'ค่าแพ็ค', align: 'right', sortable: false, value: 'packPrice'},
              { text: 'ค่าบับเบิ้ล', align: 'right', sortable: false, value: 'bubblePrice'},
              { text: 'ส่วนลด', align: 'right', sortable: false, value: 'discount'},
              { text: 'ค่าเชื่อมต่อระบบ', align: 'right', sortable: false, value: 'connectFee'},
              { text: 'ค่าธรรมเนียม Packhai', align: 'right', sortable: false, value: 'transactionFee'},
              { text: 'ค่าธรรมเนียมเชื่อมต่อระบบ', align: 'right', sortable: false, value: 'branchConnectFee'},
              { text: 'ต้นทุนค่าแพ็ค', align: 'right', sortable: false, value: 'branchBoxCost'},
              { text: 'ต้นทุนค่าขนส่ง', align: 'right', sortable: false, value: 'branchDeliveryCost'},
              { text: 'ต้นทุนค่า COD', align: 'right', sortable: false, value: 'branchCODCost'},
              { text: 'กำไรรวม', align: 'right', sortable: false, value: 'totalProfit'},
              // { text: 'เฉลี่ยกำไร/กล่อง', align: 'right', sortable: false, value: 'averageProfitPerOrder'},

              ],
      headers_group_shop:[
              { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName'},
              { text: 'จำนวนออเดอร์', align: 'right', sortable: false, value: 'orderCount'},
              { text: 'ออเดอร์เฉลี่ย / วัน', align: 'right', sortable: false, value: 'averageOrderCount'},
              { text: 'ค่าขนส่ง', align: 'right', sortable: false, value: 'deliveryPrice'},
              { text: 'ค่าบริการ COD', align: 'right', sortable: false, value: 'codPrice'},
              { text: 'ค่ากล่อง', align: 'right', sortable: false, value: 'boxPrice'},
              { text: 'ค่าแพ็ค', align: 'right', sortable: false, value: 'packPrice'},
              { text: 'ค่าบับเบิ้ล', align: 'right', sortable: false, value: 'bubblePrice'},
              { text: 'ส่วนลด', align: 'right', sortable: false, value: 'discount'},
              { text: 'ค่าเชื่อมต่อระบบ', align: 'right', sortable: false, value: 'connectFee'},
              { text: 'ค่าธรรมเนียม Packhai', align: 'right', sortable: false, value: 'transactionFee'},
              { text: 'ค่าธรรมเนียมเชื่อมต่อระบบ', align: 'right', sortable: false, value: 'branchConnectFee'},
              { text: 'ต้นทุนค่าแพ็ค', align: 'right', sortable: false, value: 'branchBoxCost'},
              { text: 'ต้นทุนค่าขนส่ง', align: 'right', sortable: false, value: 'branchDeliveryCost'},
              { text: 'ต้นทุนค่า COD', align: 'right', sortable: false, value: 'branchCODCost'},
              { text: 'กำไรรวม', align: 'right', sortable: false, value: 'totalProfit'},
              { text: 'เฉลี่ยกำไร/กล่อง', align: 'right', sortable: false, value: 'averageProfitPerOrder'},
              ],

      // new excel
      excel_headers_group_shop : [
        { label: 'ร้านค้า', field: 'shopName'},
        { label: 'จำนวนออเดอร์',  field: 'orderCount'},
        { label: 'ออเดอร์เฉลี่ย / วัน',  field: 'averageOrderCount'},
        { label: 'ค่าขนส่ง',  field: 'deliveryPrice'},
        { label: 'ค่าบริการ COD',  field: 'codPrice'},
        { label: 'ค่ากล่อง',  field: 'boxPrice'},
        { label: 'ค่าแพ็ค',  field: 'packPrice'},
        { label: 'ค่าบับเบิ้ล',  field: 'bubblePrice'},
        { label: 'ส่วนลด',  field: 'discount'},
        { label: 'ค่าเชื่อมต่อระบบ',  field: 'connectFee'},
        { label: 'ค่าธรรมเนียม Packhai',  field: 'transactionFee'},
        { label: 'ค่าธรรมเนียมเชื่อมต่อระบบ',  field: 'branchConnectFee'},
        { label: 'ต้นทุนค่าแพ็ค',  field: 'branchBoxCost'},
        { label: 'ต้นทุนค่าขนส่ง',  field: 'branchDeliveryCost'},
        { label: 'ต้นทุนค่า COD',  field: 'branchCODCost'},
        { label: 'กำไรรวม',  field: 'totalProfit'},
        { label: 'เฉลี่ยกำไร/กล่อง',  field: 'averageProfitPerOrder'},
      ],

      excel_headers_group_day:[
        { label: 'วันที่', field: 'date'},
        { label: 'ร้านค้า', field: 'shopName'},
        { label: 'จำนวนออเดอร์', field: 'orderCount'},
        { label: 'ออเดอร์เฉลี่ย / วัน', field: 'averageOrderCount'},
        { label: 'ค่าขนส่ง', field: 'deliveryPrice'},
        { label: 'ค่าบริการ COD', field: 'codPrice'},
        { label: 'ค่ากล่อง', field: 'boxPrice'},
        { label: 'ค่าแพ็ค', field: 'packPrice'},
        { label: 'ค่าบับเบิ้ล', field: 'bubblePrice'},
        { label: 'ส่วนลด', field: 'discount'},
        { label: 'ค่าเชื่อมต่อระบบ', field: 'connectFee'},
        { label: 'ค่าธรรมเนียม Packhai', field: 'transactionFee'},
        { label: 'ค่าธรรมเนียมเชื่อมต่อระบบ', field: 'branchConnectFee'},
        { label: 'ต้นทุนค่าแพ็ค', field: 'branchBoxCost'},
        { label: 'ต้นทุนค่าขนส่ง', field: 'branchDeliveryCost'},
        { label: 'ต้นทุนค่า COD', field: 'branchCODCost'},
        { label: 'กำไรรวม', field: 'totalProfit'},

      ],
    }),
    async created() {
      this.permission = JSON.parse(localStorage.getItem('permission'))
      if (!this.permission.PageReport) {
        return this.$router.push('/dashboard-statistics')
      }
      this.branchID = localStorage.getItem('Branch_BranchID')
      await this.getshop()
      await this.loaddata()
      this.page_loading = false
    },
    watch:{
    },
    computed: {
      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    methods:{
      format_number,
      format_price,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      formatDates(date) {
          if(date == null){
              return null
          } else {
          const [year, month, day] = date.split('-')
              return `${year}-${month}-${day}`
          }
      },
      changeshoplist(){
          // [],[0],[1,2,0]
          if (this.shoplist.length != 0) {
              var checkall = false
              for (let a = 0; a < this.shoplist.length; a++) {
                  if (this.shoplist[a] == 0) {
                      checkall = true
                      break;
                  }
              }
              if (checkall) {
                  this.shoplist_temp = []
                  this.shoplist = [0]
                  for (let b  = 0; b < this.select_shop.length; b++) {
                      this.shoplist.push(this.select_shop[b]['shop']['id'])
                  }
                  this.shoplist_temp = [...this.shoplist]
                  this.shoplist_temp.shift()
                  this.shoplist = [0]
              }else{
                  if (this.shoplist.length == this.select_shop.length-1) {
                      this.shoplist_temp = []
                      this.shoplist = [0]
                  }else{
                      this.shoplist_temp = this.shoplist
                  }
              }
          }
      },
      changeincomelist(){
          if (this.incomelist.length != 0) {
              var checkall = false
              for (let a = 0; a < this.incomelist.length; a++) {
                  if (this.incomelist[a] == 0) {
                      checkall = true
                      break;
                  }
              }
              if (checkall) {
                  this.incomelist_temp = []
                  this.incomelist = []
                  for (let b  = 0; b < this.select_income.length; b++) {
                      this.incomelist.push(this.select_income[b]['value'])
                  }
                  this.incomelist_temp = [...this.incomelist]
                  this.incomelist_temp.shift()
                  this.incomelist = [0]
              }else{
                  if (this.incomelist.length == this.select_income.length-1) {
                      this.incomelist_temp = []
                      this.incomelist = [0]
                  }else{
                      this.incomelist_temp = this.incomelist
                  }
              }
          }
      },
      async getshop(){
          this.loading = true
          await axios.all([
              axios.post(shopService_dotnet+'Shop/get-shop-list', {
                  "BranchId":this.branchID,
              },{headers:this.header_token}),
          ])
          .then(axios.spread((sshop) => {
              if( sshop.status == 200 ){
                  this.select_shop = sshop.data.result
                  var a = {shop:{"name":"--ทุกร้านค้า--","id":0}}
                  this.select_shop.splice(0, 0, a)
              }
          }));
          this.loading = false
      },
      async loaddata(){
            this.loading = true
            await axios.all([
                axios.post(reportService_dotnet+'BranchReport/genral-report', {
                    "DateFrom":this.search_date_from,
                    "DateTo":this.search_date_to,
                    "BranchId":this.branchID,
                    "shopIDList":this.shoplist_temp,
                    "incomeType":this.incomelist_temp
                },{headers:this.header_token}),
                axios.post(reportService_dotnet+'BranchReport/order-count', {
                    "DateFrom":this.search_date_from,
                    "DateTo":this.search_date_to,
                    "BranchId":this.branchID,
                    "shopIDList":this.shoplist_temp,
                    "incomeType":this.incomelist_temp
                },{headers:this.header_token}),
            ])
            .then(axios.spread((genral,order) => {
                if( order.status == 200 ){
                    this.Data_general = genral.data
                    this.Data_chart = order.data
                    this.search_date_from = order.data.dateFrom
                    this.search_date_to = order.data.dateTo
                }
            }));
            this.loading = false
        }
    },
  }
</script>
